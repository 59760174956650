
<template>
    <div>
        <coupons-form type="2"></coupons-form>
    </div>
</template>

<script type="text/ecmascript-6">
import couponsForm from '../couponForm/index'

export default {
    components: {
        couponsForm
    },
}
</script>
